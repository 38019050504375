import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as FavoriteIcon } from '../../../assets/images/svg/favorite.svg';
import { ReactComponent as UnfavoriteIcon } from '../../../assets/images/svg/unfavorite.svg';
import { useSelector, useDispatch } from 'react-redux';
import PlayButton from '../../common/PlayButton';
import { useLoginForm } from '../../../helpers/hooks';
import { selectAuthInfo } from '../../../../features/auth/authSlice';
import { useTranslation } from 'react-i18next';
import { track } from '@amplitude/analytics-browser';
import { toast } from 'react-toastify';
import { unwrapResult } from '@reduxjs/toolkit';
import { getUserProfile } from '../../../../features/user/userSlice';

import {
  addFavoriteChannel,
  removeFavoriteChannel,
  updatefetchChannelsGrouped
} from '../../../../features/channels/channelsSlice';

// Assets
import './ChannelCard.scss';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

const ChannelCard = ({
  channelId,
  channelImgSrc,
  channelTitle,
  isChannelLocked,
  duration,
  isTelecast,
  epg,
  genre_id,
  isFavorite,
  channel,
  updateFavoriteChannels = () => {}
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { openLoginForm } = useLoginForm(false);
  const { isAuthorized } = useSelector(selectAuthInfo);
  const user = useSelector(getUserProfile);
  const [disableHandleFavoriteChannel, setDisableHandleFavoriteChannel] = useState(false);
  const handleSaveScrollPosition = () => {
    sessionStorage.setItem(location.pathname, window.scrollY);
  };
  const analytycEvent = () => {
    if (location.pathname.includes('search')) {
      const eventProperties = {
        ContentId: channelId,
        ContentTitle: channelTitle,
        ContentType: isTelecast ? 'epg' : 'channel'
      };
      track('Search Result Clicked', eventProperties);
    }
  };
  const onclickChannel = (e) => {
    handleSaveScrollPosition();
    if (!isAuthorized && isChannelLocked) {
      e.preventDefault();
      openLoginForm({ ...location, pathname: `/channel/${channelId}/${genre_id}` });
      return;
    }
    if (!isChannelLocked) {
      const eventProperties = {
        ChannelId: channelId,
        ChannelTitle: channelTitle,
        ChannelGenre: genre_id,
        RecordTitle: epg?.name,
        Subscription: isTelecast ? null : user?.subscription?.product.name
      };
      track(isTelecast ? 'Channel Record Started' : 'Channel Stream Started', eventProperties);
    }
  };
  const handleFavoriteChannel = async () => {
    if (disableHandleFavoriteChannel) return;
    const eventProperties = {
      ChannelId: channelId,
      ChannelTitle: channelTitle,
      ChannelGenre: genre_id
    };
    setDisableHandleFavoriteChannel(true);
    if (isFavorite) {
      await unwrapResult(await dispatch(removeFavoriteChannel({ channelId: channelId })));
      await updateFavoriteChannels('remove', {
        ...channel,
        briz_billing_product_info: { ...channel.briz_billing_product_info, in_favorites: false }
      });
      await unwrapResult(await dispatch(updatefetchChannelsGrouped()));
      toast.error(t('notifications.WAS_REMOVED_FROM_FAVORITES', { name: channelTitle }));
      track('Channel Unfavorited', eventProperties);
    } else {
      await unwrapResult(await dispatch(addFavoriteChannel({ channelId: channelId })));
      await updateFavoriteChannels('add', {
        ...channel,
        briz_billing_product_info: { ...channel.briz_billing_product_info, in_favorites: true }
      });
      await unwrapResult(await dispatch(updatefetchChannelsGrouped()));
      toast.success(t('notifications.WAS_ADDED_TO_FAVORITES', { name: channelTitle }));
      track('Channel Favorited', eventProperties);
    }
    setDisableHandleFavoriteChannel(false);
  };
  return (
    <div
      className={`ChannelCardWrap ${isFavorite ? 'inFavorite' : ''}`}
      onClick={() => analytycEvent()}
    >
      {isAuthorized ? (
        <button
          onClick={handleFavoriteChannel}
          className="ChannelCard__favorites-btn"
          type="button"
        >
          {isFavorite ? <FavoriteIcon /> : <UnfavoriteIcon />}
        </button>
      ) : null}
      <div className="ChannelCard__link-wrapper">
        <Link
          onClick={(e) =>
            onclickChannel(
              e,
              epg
                ? `/channel/${channelId}/${genre_id}?from=${epg.start}&to=${epg.end}&epgId=${epg.id}`
                : `/channel/${channelId}/${genre_id}`
            )
          }
          to={
            epg
              ? `/channel/${channelId}/${genre_id}?from=${epg.start}&to=${epg.end}&epgId=${epg.id}`
              : `/channel/${channelId}/${genre_id}`
          }
          className={classNames('ChannelCard', isChannelLocked && 'ChannelCard--locked')}
        >
          <img src={channelImgSrc} alt={channelTitle} loading="lazy" />
          <PlayButton
            dataContent={t('common.watch')}
            playButtonClassName="ChannelCard__overlay-btn"
          />
        </Link>
      </div>
      <Link
        onClick={(e) =>
          onclickChannel(
            e,
            epg
              ? `/channel/${channelId}/${genre_id}?from=${epg.start}&to=${epg.end}&epgId=${epg.id}`
              : `/channel/${channelId}/${genre_id}`
          )
        }
        to={
          epg
            ? `/channel/${channelId}/${genre_id}?from=${epg.start}&to=${epg.end}&epgId=${epg.id}`
            : `/channel/${channelId}/${genre_id}`
        }
        className="ChannelCard__nameChannel"
      >
        {channelTitle}
      </Link>

      {duration ? (
        <div className="ChannelCard__duration">{`${t('titles.record')}${duration}`}</div>
      ) : null}
    </div>
  );
};

ChannelCard.propTypes = {
  isChannelLocked: PropTypes.bool.isRequired,
  channelId: PropTypes.number.isRequired,
  channelImgSrc: PropTypes.string.isRequired,
  channelTitle: PropTypes.string,
  duration: PropTypes.string,
  isTelecast: PropTypes.bool,
  epg: PropTypes.shape({
    name: PropTypes.string,
    start: PropTypes.number,
    end: PropTypes.number,
    id: PropTypes.string
  }),
  genre_id: PropTypes.string,
  isFavorite: PropTypes.bool,
  channel: PropTypes.shape({
    id: PropTypes.number,
    briz_billing_product_info: PropTypes.shape({
      in_favorites: PropTypes.bool,
      is_available: PropTypes.bool,
      is_free: PropTypes.bool
    })
  }),
  updateFavoriteChannels: PropTypes.func
};

export default ChannelCard;

export const ChannelCardSkeleton = () => {
  return (
    <div className="ChannelCardSkeleton Skeleton">
      <div className="ChannelCardSkeleton__image" />
      <div className="CardsItemSkeleton__title" />
    </div>
  );
};

export const ChannelsCardsSkeleton = ({ length = 20, title }) => {
  const skeleton = Array.from({ length: length }).map((item, index) => (
    <ChannelCardSkeleton key={index} />
  ));

  return (
    <div className="ChannelsSubscriptionList">
      <h2 className="ChannelGenresTitle">{title}</h2>
      <div className="AppTabsWrapper">
        <div>
          <div className="AppTabs__tab-content">
            <div className="TabContent">
              <div className="ChannelsGrid">
                <div>
                  <div className="ChannelTabsSkeleton">
                    <div className="TopTabs Skeleton">
                      <div className="ChannelTabSkeleton" />
                      <div className="ChannelTabSkeleton" />
                      <div className="ChannelTabSkeleton" />
                      <div className="ChannelTabSkeleton" />
                      <div className="ChannelTabSkeleton" />
                      <div className="ChannelTabSkeleton" />
                      <div className="ChannelTabSkeleton" />
                    </div>
                    <div className="BottomTabs Skeleton">
                      <div className="ChannelTabSkeleton" />
                    </div>
                  </div>
                </div>
                <div className="ChannelsGrid__list"> {skeleton} </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ChannelsCardsSkeleton.propTypes = {
  length: PropTypes.number,
  title: PropTypes.string.isRequired
};
